<template>
  <div class="table-responsive">
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :bordered="true"
      :row-key="(rec) => rec.id"
      :pagination="pagination"
      :scroll="{ x: true }"
      :loading="tableLoading"
    >
      <template #rateType="{text}">
        <span class="align-middle text-primary text-uppercase font-size-12 badge badge-light">
          {{ text || 'N/A' }}
        </span>
      </template>
      <template #customerRender="{text}">
        {{ getCustomerName(text) || 'N/A' }}
      </template>
      <template #vendorRender="{text}">
        {{ getVendorName(text) || 'N/A' }}
      </template>
      <template #totalRates="{record}">
        {{ record?.doc?.zoneRates?.length }}
      </template>
      <template #expandedRowRender="{record}">
        <div class="p-3">
          <zone-rate-table :shipping-rates-doc="record?.doc" />
        </div>
      </template>
      <template #actions="{record}">
        <div>
          <ButtonGroupActions
            :button-list="actionButtonList"
            @handleRefreshClick="handleRefreshClick(record)"
            @handleDeleteClick="handleDeleteClick(record)"
          />
        </div>
      </template>
    </a-table>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, reactive, ref } from 'vue'
import { ShippingRatesDocument } from '@/types/firebaseCollectionContracts/ShippingRatesDocument'
// import { useStore } from 'vuex'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import ZoneRateTable from './ZoneRateTable.vue'
import { useSearchMapping } from '@/hooks/useSearchMappingHook'
import { ActionButtonList } from '@/types/enums/ActionButtonList'
import ButtonGroupActions from '@/components/General/ButtonGroupActions.vue'
import { useStore } from 'vuex'
import * as actions from '@/store/actions.type'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { DocumentActions } from '@/types/enums/DocumentActions'

const columns = [
  {
    title: 'Type',
    dataIndex: 'doc.rateType',
    key: 'rateType',
    slots: { customRender: 'rateType' },
  },
  {
    title: 'Customer',
    dataIndex: 'doc.customerId',
    key: 'customerId',
    slots: { customRender: 'customerRender' },
  },
  {
    title: 'Vendor',
    dataIndex: 'doc.vendorId',
    key: 'vendorId',
    slots: { customRender: 'vendorRender' },
  },
  {
    title: 'Total Rates',
    key: 'totalRates',
    slots: { customRender: 'totalRates' },
  },
  {
    title: 'Actions',
    key: 'actions',
    slots: { customRender: 'actions' },
  },
]

export default defineComponent({
  name: 'RatesTable',
  components: { ZoneRateTable, ButtonGroupActions },
  props: {
    ratesList: {
      type: Object as PropType<IAppDocument<ShippingRatesDocument>[] | null>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()
    const dataSource = computed(() => props.ratesList)
    const { customersSearchList, vendorsSearchList } = useSearchMapping()
    const actionButtonList = [ActionButtonList.REFRESH, ActionButtonList.DELETE]
    const tableLoading = ref<boolean>(false)

    // const totalTableRecords = computed(() => store.getters['appGlobals/totalRates'] as number)
    const totalTableRecords = 100 // Currently no way to know total Rates doc
    const showTotal = (total: number, range: Array<any>) => {
      return `${range[0]}-${range[1]} of ${total} items`
    }

    const getCustomerName = (id: string) => {
      return customersSearchList.value.find((x) => x.id === id)?.displayName
    }

    const getVendorName = (id: string) => {
      return vendorsSearchList.value.find((x) => x.id === id)?.displayName
    }

    const handleRefreshClick = async (record: IAppDocument<ShippingRatesDocument>) => {
      tableLoading.value = true
      await store.dispatch(`rates/${actions.RatesAction.FETCH_RATES_BY_ID}`, {
        docId: record.id,
      })
      tableLoading.value = false
    }

    const handleDeleteClick = async (record: IAppDocument<ShippingRatesDocument>) => {
      tableLoading.value = true
      const docOperation: DocumentOperation<null> = {
        action: DocumentActions.DELETE,
        id: record.id,
        payload: null,
        audit: null,
      }
      await store.dispatch(`rates/${actions.RatesAction.SET_OR_DELETE_SHIPPING_RATE}`, docOperation)
      tableLoading.value = false
    }

    const pagination = reactive({
      position: 'bottom',
      showSizeChanger: true,
      showQuickJumper: false,
      total: totalTableRecords || 0,
      simple: false,
      pageSize: 10,
      showTotal: showTotal,
    })

    return {
      columns,
      pagination,
      dataSource,
      getCustomerName,
      getVendorName,
      actionButtonList,
      handleRefreshClick,
      handleDeleteClick,
      tableLoading,
    }
  },
})
</script>

<style lang="scss">
.ant-pagination-item,
.ant-pagination-jump-next,
.ant-pagination-jump-prev {
  display: none !important;
}
// .ant-table td {
//   white-space: nowrap;
// }
</style>
