
import { computed, defineComponent, PropType, reactive, ref } from 'vue'
import RatesForm from '../rates/new/index.vue'
import { useSearchMapping } from '@/hooks/useSearchMappingHook'
import { IZoneRate } from '@/types/interfaces/IZoneRate'
import RateCardTable from './RateCardTable.vue'
import { ShippingRatesDocument } from '@/types/firebaseCollectionContracts/ShippingRatesDocument'
import { RateFormData } from '@/types/appcontracts/RateFormData'
import { RateTypes } from '@/types/enums/RateTypes'
import ModalSlot from '@/components/General/ModalSlot.vue'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { DocumentActions } from '@/types/enums/DocumentActions'
import * as actions from '@/store/actions.type'
import { useStore } from 'vuex'
import ButtonGroupActions from '@/components/General/ButtonGroupActions.vue'
import { ActionButtonList } from '@/types/enums/ActionButtonList'

interface IZoneRateTable {
  zoneRate: IZoneRate
  zoneName: string
  vendorName: string
  serviceName: string
  vendorId: string
}

const columns = [
  {
    title: 'Zone Name',
    dataIndex: 'zoneName',
    key: 'zoneName',
  },
  {
    title: 'Document Type',
    dataIndex: 'zoneRate.documentType',
    key: 'docType',
    slots: { customRender: 'docType' },
  },
  {
    title: 'Vendor',
    dataIndex: 'vendorName',
    key: 'vendorName',
  },
  {
    title: 'Service',
    dataIndex: 'serviceName',
    key: 'serviceName',
  },
  {
    title: 'Actions',
    key: 'actions',
    slots: { customRender: 'actions' },
  },
]

export default defineComponent({
  name: 'ZoneRateTable',
  components: { RateCardTable, RatesForm, ModalSlot, ButtonGroupActions },
  props: {
    shippingRatesDoc: {
      type: Object as PropType<ShippingRatesDocument | null>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()
    // const zoneRatesDataSource = computed(() => props.zoneRatesList)
    const { getZoneDetailsById } = useSearchMapping()
    const isRateModalVisible = ref(false)
    const editFormData = ref<RateFormData | null>(null)
    const tableLoading = ref<boolean>(false)
    const actionButtonList = [ActionButtonList.EDIT, ActionButtonList.DELETE]
    const dataSource = computed(() => {
      return props.shippingRatesDoc?.zoneRates?.map((x) => {
        const zoneDetails = getZoneDetailsById(x.zoneId || '')
        return {
          zoneRate: x,
          zoneName: zoneDetails.name,
          vendorName: zoneDetails.vendorName,
          serviceName: zoneDetails.serviceName,
          vendorId: zoneDetails.vendorId,
        } as IZoneRateTable
      })
    })
    const totalTableRecords = computed(() => dataSource.value?.length) // Currently no way to know total Rates doc
    const showTotal = (total: number, range: Array<any>) => {
      return `${range[0]}-${range[1]} of ${total} items`
    }

    const handleEditClick = (record: IZoneRateTable) => {
      editFormData.value = {
        ...record.zoneRate,
        customerId: props.shippingRatesDoc?.customerId || null,
        vendorId: record.vendorId || null,
        rateType: props.shippingRatesDoc?.rateType || RateTypes.Customer,
      }

      isRateModalVisible.value = true
    }

    const handleDeleteClick = async (record: IZoneRateTable) => {
      const del: RateFormData = {
        ...record.zoneRate,
        customerId: props.shippingRatesDoc?.customerId || null,
        vendorId: record.vendorId || null,
        rateType: props.shippingRatesDoc?.rateType || RateTypes.Customer,
      }
      tableLoading.value = true
      const docOperation: DocumentOperation<RateFormData> = {
        action: DocumentActions.DELETE,
        id: del.customerId || del.vendorId,
        payload: del,
        audit: null,
      }
      await store.dispatch(`rates/${actions.RatesAction.SET_OR_DELETE_SHIPPING_RATE}`, docOperation)
      tableLoading.value = false
    }

    const pagination = reactive({
      position: 'bottom',
      showSizeChanger: true,
      showQuickJumper: false,
      total: totalTableRecords.value || 0,
      simple: false,
      pageSize: 10,
      showTotal: showTotal,
    })

    return {
      columns,
      pagination,
      dataSource,
      isRateModalVisible,
      editFormData,
      handleEditClick,
      tableLoading,
      actionButtonList,
      handleDeleteClick,
    }
  },
})
