
import { useStore } from 'vuex'
import * as actions from '@/store/actions.type'
import { ref, defineComponent, computed, onMounted } from 'vue'
import { SyncOutlined } from '@ant-design/icons-vue'
import ShippingRatesTable from './ShippingRatesTable.vue'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import { ShippingRatesDocument } from '@/types/firebaseCollectionContracts/ShippingRatesDocument'
import RatesForm from '../rates/new/index.vue'
import ModalSlot from '@/components/General/ModalSlot.vue'

export default defineComponent({
  name: 'Rates',
  components: { SyncOutlined, ShippingRatesTable, RatesForm, ModalSlot },
  setup() {
    const store = useStore()
    const isRateModalVisible = ref(false)
    const isRatesLoading = computed(() => store.state.rates.shippingRatesLoading)
    const ratesList = computed(
      () => store.getters[`rates/shippingRatesList`] as IAppDocument<ShippingRatesDocument>[],
    )
    const ratesExists = computed(() => ratesList.value.length !== 0)
    const reloadIconType = ref(false)

    const handleDataReload = async () => {
      reloadIconType.value = true
      await store.dispatch(`rates/${actions.RatesAction.FETCH_SHIPPING_RATES_ON_LOAD}`)
      reloadIconType.value = false
    }

    onMounted(() => {
      if (ratesList.value.length === 0) {
        handleDataReload()
      }
    })
    return {
      isRatesLoading,
      ratesExists,
      reloadIconType,
      handleDataReload,
      ratesList,
      isRateModalVisible,
    }
  },
})
