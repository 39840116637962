
import { computed, defineComponent, PropType, reactive } from 'vue'
import { IZoneRate } from '@/types/interfaces/IZoneRate'

const ratesColumns = [
  {
    title: 'From (kg)',
    dataIndex: 'minw',
    key: 'minw',
  },
  {
    title: 'Upto (kg)',
    dataIndex: 'maxw',
    key: 'maxw',
  },
  {
    title: 'Cost (INR)',
    dataIndex: 'cost',
    key: 'cost',
  },
]

const addRateColumns = [
  {
    title: 'Additional Weight (kg)',
    dataIndex: 'weight',
    key: 'weight',
  },
  {
    title: 'Cost',
    dataIndex: 'cost',
    key: 'cost',
  },
]

export default defineComponent({
  name: 'RateCardTable',
  props: {
    zoneRate: {
      type: Object as PropType<IZoneRate | null>,
      required: true,
    },
  },
  setup(props) {
    // const store = useStore()
    const rateCardDataSource = computed(() => props.zoneRate?.rateCard)
    const additionalRateSource = computed(() => [props.zoneRate?.additionalRate])
    const rateCardTableRecords = computed(() => rateCardDataSource.value?.length || 0)
    const showTotal = (total: number, range: Array<any>) => {
      return `${range[0]}-${range[1]} of ${total} items`
    }
    const handleViewClick = (record: any) => {
      console.log('Rec: ', record)
    }

    const rateCardPagination = reactive({
      position: 'bottom',
      showSizeChanger: true,
      showQuickJumper: false,
      total: rateCardTableRecords.value || 0,
      simple: false,
      pageSize: 10,
      showTotal: showTotal,
    })

    return {
      ratesColumns,
      rateCardPagination,
      rateCardDataSource,
      additionalRateSource,
      addRateColumns,
      handleViewClick,
    }
  },
})
