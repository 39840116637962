<template>
  <div>
    <div class="d-flex flex-wrap border-bottom mb-3">
      <div class="mt-4 mr-auto">
        <h4>Rates</h4>
      </div>

      <div>
        <div
          class="btn btn-success text-blue btn-with-addon text-nowrap my-3 mr-3"
          @click="isRateModalVisible = true"
        >
          <span class="btn-addon">
            <i class="btn-addon-icon fe fe fe-plus"></i>
          </span>
          New
        </div>
      </div>
      <div class="dropdown my-3 mr-3">
        <a-dropdown placement="bottomLeft" :trigger="['click']">
          <a class="btn btn-light text-blue dropdown-toggle" href="javascript: void(0);">Actions</a>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <router-link
                  :to="{
                    name: 'importFile',
                    query: { entity: 'rates' },
                  }"
                >
                  Import
                </router-link>
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;">Export</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
      <div class="d-flex flex-column justify-content-center">
        <a-tooltip title="Refresh">
          <button type="button" class="btn btn-light" @click="handleDataReload">
            <SyncOutlined :spin="reloadIconType" />
          </button>
        </a-tooltip>
      </div>
    </div>
    <div>
      <a-skeleton :loading="isRatesLoading" active>
        <div class="text-center my-5" v-if="!ratesExists">
          <h3>Create Rates here.</h3>
          <p class="text-muted">
            Create and manage your rates, all in one place.
          </p>
          <!-- <template> -->
          <div>
            <a-empty :description="false" />
          </div>
          <!-- </template> -->
          <div>
            <div>
              <div class="btn btn-success my-2 text-uppercase" @click="isRateModalVisible = true">
                Create New Rate
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row">
            <div class="col-lg-6"></div>
          </div>
          <div>
            <ShippingRatesTable :rates-list="ratesList" />
          </div>
        </div>
      </a-skeleton>
    </div>

    <div>
      <ModalSlot
        v-model:modal-visible="isRateModalVisible"
        modal-title="Add Rate"
        modal-width="800"
      >
        <div style="height: 430px; width: 100%; overflow-y: auto; overflow-x: hidden">
          <RatesForm />
        </div>
      </ModalSlot>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import * as actions from '@/store/actions.type'
import { ref, defineComponent, computed, onMounted } from 'vue'
import { SyncOutlined } from '@ant-design/icons-vue'
import ShippingRatesTable from './ShippingRatesTable.vue'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import { ShippingRatesDocument } from '@/types/firebaseCollectionContracts/ShippingRatesDocument'
import RatesForm from '../rates/new/index.vue'
import ModalSlot from '@/components/General/ModalSlot.vue'

export default defineComponent({
  name: 'Rates',
  components: { SyncOutlined, ShippingRatesTable, RatesForm, ModalSlot },
  setup() {
    const store = useStore()
    const isRateModalVisible = ref(false)
    const isRatesLoading = computed(() => store.state.rates.shippingRatesLoading)
    const ratesList = computed(
      () => store.getters[`rates/shippingRatesList`] as IAppDocument<ShippingRatesDocument>[],
    )
    const ratesExists = computed(() => ratesList.value.length !== 0)
    const reloadIconType = ref(false)

    const handleDataReload = async () => {
      reloadIconType.value = true
      await store.dispatch(`rates/${actions.RatesAction.FETCH_SHIPPING_RATES_ON_LOAD}`)
      reloadIconType.value = false
    }

    onMounted(() => {
      if (ratesList.value.length === 0) {
        handleDataReload()
      }
    })
    return {
      isRatesLoading,
      ratesExists,
      reloadIconType,
      handleDataReload,
      ratesList,
      isRateModalVisible,
    }
  },
})
</script>
